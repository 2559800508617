<template>
  <div class="home pt-4">
    <b-container>
      <b-row>
        <MainLeftBlock :dataSport="dataSport"></MainLeftBlock>
        <MainCenterBlock :dataSport="dataSport"></MainCenterBlock>
        <MainRightBlock :dataSport="dataSport"></MainRightBlock>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import MainLeftBlock from "@/components/blocks/rocket_league/MainLeftBlock.vue";
import MainCenterBlock from "@/components/blocks/rocket_league/MainCenterBlock.vue";
import MainRightBlock from "@/components/blocks/rocket_league/MainRightBlock.vue";

export default {
  name: "rocket_league_home",
  data() {
    return {
      dataSport: {
        textClass: "rl-text-color",
        kindOfSport: "rocket-league",
        sportId: 4,
        link_api: "rl"
      }
    };
  },
  components: {
    MainLeftBlock,
    MainCenterBlock,
    MainRightBlock
  },
  mounted() {},
  metaInfo() {
    return {
      title: this.$t("meta.sports.rl.main.title"),
      titleTemplate: "H2H Liga | %s",
      meta: [
        {
          name: "description",
          content: this.$t("meta.sports.rl.main.description")
        },
        { name: "keywords", content: this.$t("meta.sports.rl.main.keywords") }
      ]
    };
  }
};
</script>
<style>
.game-border-bottom.ehockey_border:not(:last-child):hover {
  border-bottom: 1px solid rgba(70, 206, 255, 0.58) !important;
  transition: all 0.5s;
}
</style>
